@tailwind base;
@tailwind components;
@tailwind utilities;

.button_mint, .column_mint {
    cursor: pointer;
}

body {
    overflow: visible !important; /* Hide scrollbars */
    margin-bottom: 50px;
}

::-webkit-scrollbar {
    display: none;
}

/* Track */
::-webkit-scrollbar-track {
    display: none;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    display: none;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    display: none;
}

#WEB3_CONNECT_MODAL_ID {
    font-family: Russo One, sans-serif;
}